export default [
  {
    text: 'ID',
    align: 'left',
    sortable: true,
    value: 'id',
    width: '80px'
  },
  {
    text: 'Телефон',
    align: 'left',
    sortable: true,
    value: 'phone',
    width: '130px'
  },
  {
    text: 'Имя',
    align: 'left',
    sortable: true,
    value: 'name',
    width: '200px'
  },
  {
    text: 'Статус',
    align: 'left',
    sortable: true,
    value: 'status',
    width: '130px'
  },
  {
    text: 'Статус владельца',
    align: 'left',
    sortable: false,
    value: 'owner_status',
    width: '140px'
  },
  {
    text: 'Описание',
    align: 'left',
    sortable: true,
    value: 'description',
    width: '481px'
  }
]
