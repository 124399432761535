<template>
  <div
    v-scroll="handleScrollPosition"
  >
    <div class="d-flex align-center">
      <v-btn
        small
        class="primary ml-3"
        @click="$emit('detail', {id: null})"
      >
        <v-icon
          small
          class="mr-3"
        >mdi-plus
        </v-icon>
        Добавить телефон
      </v-btn>
      <Controls
        :filters="value.requestParams.filters"
        @input="handleControls($event)"
      />
    </div>
    <Table
      :options="value.requestParams.options"
      @input="localRequestParams.options = $event"
      :loading="loadingTable"
      :items="items"
      :items-length="itemsLength"
      @detail="$emit('detail', $event)"
      :opened.sync="localState.openedItems"
    />
  </div>
</template>

<script>
import Table from './List/Table'
import Controls from './List/Controls'
import list from '@/components/mixins/list'

export default {
  mixins: [list],
  name: 'List',
  components: { Controls, Table },
  data: function () {
    return {
      fillTableUrl: '/phone'
    }
  }
}
</script>

<style>

</style>
