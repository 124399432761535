<template>
  <v-container
    fluid
    class="pt-3 pb-0"
  >
    <v-row class="text-left">
      <v-col
        cols="8"
        class="text-left pt-0 pb-0"
      >
        <div class="d-flex align-start">
          <Autocomplete
            :value="filters.phoneFilter"
            @input="localFilters.phoneFilter = $event"
            item-value="id"
            item-text="phone"
            placeholder="поиск по номеру телефона"
            prepend-icon="mdi-database-search"
            mask="8 ### ### ## ##"
            url="/phone/autocomplete"
          />
          <v-select
            :value="localFilters.clientCategory"
            @input="handleClientCategoryFilter($event)"
            :items="ownerStatusesWithEmpty"
            label="Выберите категорию клиентов"
            prepend-icon="mdi-playlist-check"
            elevation="1"
            class="text-left pl-3"
            item-value="id"
            item-text="value"
          ></v-select>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import controls from '@/components/mixins/controls/controls'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'

export default {
  mixins: [controls, serviceDataComputeds],
  name: 'Controls',
  components: { Autocomplete },
  data: () => ({
    localFilters: {
      clientCategory: null,
      isVerified: null,
      phoneFilter: null
    }
  })
}
</script>

<style scoped>

</style>
