<template>
  <v-container fluid>
    <v-row class="text-left">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :options="options"
          @update:options="$emit('input', $event)"
          :server-items-length="itemsLength"
          :loading="loading"
          :footer-props="{itemsPerPageOptions:  [20,100,300]}"
          class="elevation-1"
          multi-sort
        >
          <template v-slot:item="{ item }">
            <tr
              :class="classByStatus(item)"
              @click="detail(item.id)"
            >
              <td>{{item.id}}</td>
              <td>{{maskValue(item.phone, '8 ### ### ## ##')}}</td>
              <td>{{item.name}}</td>
              <td>{{formatValue('phone.status', item.status)}}</td>
              <td><div
                v-for="status in item.owner_status"
                :key="Number(status)"
              >
                {{ status ? formatValue('phone.owner_status', Number(status)) : '-' }}
              </div></td>
              <td>{{item.description}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import headers from './header'
import formatters from '@/components/mixins/formatters'
import table from '@/components/mixins/table'

export default {
  mixins: [formatters, table],
  name: 'Table',
  data: () => ({
    headers: headers
  }),
  methods: {
    classByStatus (item) {
      let className = ''
      className = this.phoneStatusClass(item.status)
      if (this.opened.includes(item.id)) {
        className += ' selected-item'
      }
      return className
    }
  }
}
</script>

<style scoped>

</style>
